<template>
  <div class="flex flex-col h-full bg-night-surface">
    <InstructionHeader
			:title="$t('Controleren')"
			:instruction="$t(`<strong>Controleer</strong> of de voorraad van de lade klopt met de <strong>onderstaande voorraad.</strong>`)"
			background="blue-alt" />

		<div class="flex-grow">
			<MachineShape>
				<MachineGridShelve
					:shelve="currentRefillingShelve"
					:is-correcting-stock="true" />

				<CorrectInventory
					v-if="correctingSlot"
					:shelve-slot="correctingSlot"
					:percentage="correctingSlotsPercentage"
					@next-slot="correctNextSlot" />

				<template #bottom>
					<div class="grid grid-cols-2 gap-2">
						<AppButton @click="startCorrectingInventory" type="secondary" width="full" :dark-only="true">{{ $t('Corrigeren') }}</AppButton>

						<RouterLink :to="{ name: 'OperatorRefillProducts' }">
							<AppButton width="full">{{ $t('Klopt, verder ->') }}</AppButton>
						</RouterLink>
					</div>
				</template>
			</MachineShape>
		</div>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from "@vue/composition-api";
import InstructionHeader from "@/components/NewOperator/InstructionHeader";
import MachineShape from "@/components/NewOperator/MachineShape";
import MachineGridShelve from "@/components/NewOperator/MachineGridShelve";
import CorrectInventory from "@/components/NewOperator/CorrectInventory";
import AppButton from '@/components/Interface/AppButton';
import useOperator from "@/composables/useOperator";

export default defineComponent({
  components: {
    InstructionHeader,
    MachineShape,
		CorrectInventory,
		MachineGridShelve,
		AppButton,
  },

	setup(props, { root }) {
		const { currentRefillingShelve } = useOperator();

		const correctingSlot = ref(null);

		const startCorrectingInventory = () => {
			correctingSlot.value = currentRefillingShelve.value.slots[0];
		}

		const currentSlotIndex = computed(() => {
			return currentRefillingShelve.value.slots.findIndex(slot => slot.id === correctingSlot.value.id);
		});

		const correctNextSlot = () => {
			const nextSlot = currentRefillingShelve.value.slots[currentSlotIndex.value + 1];

			if (!nextSlot) {
				correctingSlot.value = null;
				return;
			}

			correctingSlot.value = nextSlot;
		}

		const correctingSlotsPercentage = computed(() => {
			return ((currentSlotIndex.value) + 0.5) * (100 / currentRefillingShelve.value.slots.length);
		});

		return {
			currentRefillingShelve,
			correctingSlot,
			startCorrectingInventory,
			correctNextSlot,
			correctingSlotsPercentage,
		}
	}
});
</script>
