<template>
  <div class="flex flex-col h-full bg-night-surface">
    <InstructionHeader
			:title="$t('Let\'s refill!')"
			:instruction="$t('Laten we direct <strong>beginnen!</strong> 💪')"
			background="pink" />

		<div class="px-4 pt-4 pb-12 flex-grow flex flex-col justify-between space-y-4 overflow-auto">
			<div class="grid gap-4">
				<h3 class="text-base leading-6 font-medium text-white">{{ $t('Voordat je begint...') }}</h3>

				<div class="grid gap-2">
					<ChecklistItem
						v-for="item, index in [
							`Sta je bij de Junea ${machineType === 'drinks' ? 'Drinks' : 'Snacks'} automaat met nummer ${machineNumber}?`,
							'Heb je de geleverde doos met nieuwe voorraad bij de hand?',
							'Beschik je over voldoende tijd? Het bijvullen kost circa 5 tot 10 minuten.',
						]"
						:key="index">
						{{ item }}
					</ChecklistItem>
				</div>
			</div>

			<AppButton @click="startRefill" width="full">Aan de slag! -></AppButton>
		</div>
  </div>
</template>

<script>
import { defineComponent, computed } from "@vue/composition-api";
import InstructionHeader from "@/components/NewOperator/InstructionHeader";
import ChecklistItem from "@/components/NewOperator/Intro/ChecklistItem";
import AppButton from '@/components/Interface/AppButton';
import useOperator from "@/composables/useOperator";

export default defineComponent({
  components: {
    InstructionHeader,
    ChecklistItem,
		AppButton,
  },

	setup(_, { root, emit }) {
		const { OperatorAPI, machineOperatorData } = useOperator();

		const startRefill = async () => {
			await OperatorAPI.fetchMachineOperatorData(root.$route.query.machine);
			root.$router.push({ name: 'OperatorReceivedItems' });
		}

		const machineNumber = computed(() => machineOperatorData.value?.machine_number ?? root.$route.query.machine);
		const machineType = computed(() => machineOperatorData.value?.type ?? root.$route.query.type);

		return {
			startRefill,
			machineNumber,
			machineType,
		}
	}
});
</script>
