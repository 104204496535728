<template>
	<div class="bg-white rounded-lg p-6 flex flex-col gap-4 relative -mt-2">
		<span class="arrow-above" :style="{ left: `calc(${percentage}% - 12px)` }"></span>

		<div v-if="refillDataForSlot.initalStock > 0" class="flex justify-center space-x-4">
			<div
				v-if="shelveSlot.product && shelveSlot.product.image"
				class="relative h-24"
				:style="{ flexBasis: `calc(40% + ${refillDataForSlot.initalStock * 4}px)` }">

				<img
					v-for="index in refillDataForSlot.initalStock"
					:src="shelveSlot.product.image"
					class="object-contain rounded-lg h-24 w-24 absolute top-0"
					:style="getStackedImageStyle(index)"
				/>
			</div>

			<div class="flex flex-col space-y-1 justify-center items-center bg-white relative z-10 px-4">
				<span class="text-3xl leading-6 text-black font-bold">{{ refillDataForSlot.initalStock }}</span>
				<span class="text-sm leading-5 font-medium text-gray-300">stuks</span>
			</div>
		</div>

		<div v-if="refillDataForSlot.initalStock === 0" class="flex flex-col space-y-1 justify-center items-center py-6">
			<span class="text-3xl leading-6 text-black font-bold">Geen</span>
			<span class="text-sm leading-5 font-medium text-gray-300">Voorraad</span>
		</div>

		<div class="grid grid-cols-2 gap-2">
			<AmountSelector
				:amount="refillDataForSlot.initalStock"
				:max="shelveSlot.stock"
				@increase="refillDataForSlot.initalStock = refillDataForSlot.initalStock + 1"
				@decrease="refillDataForSlot.initalStock = refillDataForSlot.initalStock - 1"
			/>

			<AppButton @click="$emit('next-slot')" width="full">{{ $t('Volgende') }}</AppButton>
		</div>

		<div class="flex justify-center">
			<span @click="setOriginalAmount" class="text-black underline text-sm leading-5 font-medium text-center cursor-pointer">
				Orgineel aantal
			</span>
		</div>
	</div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
import AppButton from '@/components/Interface/AppButton';
import AmountSelector from '@/components/NewOperator/AmountSelector';
import useOperator from '@/composables/useOperator';

export default defineComponent({
	props: {
		shelveSlot: {
			type: Object,
			required: true,
		},
		percentage: {
			type: Number,
			required: true,
		},
	},

	components: {
		AppButton,
		AmountSelector,
	},

	setup(props) {
		const { refillData } = useOperator();

		const refillDataForSlot = computed(() => {
			return refillData.value.find((slot) => slot.slotId === props.shelveSlot.id);
		});

		const getStackedImageStyle = (index) => {
      const zIndex = refillDataForSlot.value.initalStock - index;
      const leftOffset = index * 14;
      const scaleFactor = 1 - (index * 0.05);

      return {
        zIndex: zIndex,
        left: `${leftOffset}px`,
        transform: `scale(${scaleFactor})`
      };
    };

		const setOriginalAmount = () => {
			refillDataForSlot.value.initalStock = props.shelveSlot.stock;
		};

    return {
      getStackedImageStyle,
			refillDataForSlot,
			setOriginalAmount,
    };
	},
});
</script>

<style scoped>
.arrow-above {
	content: '';
	position: absolute;
	top: -8px;
	width: 24px;
	height: 8px;
	background-image: url("data:image/svg+xml,%3Csvg width='24' height='8' preserveAspectRatio='none' viewBox='0 0 41 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.1963 0L0.196289 8H40.1963L20.1963 0Z' fill='white'/%3E%3C/svg%3E%0A");
}
</style>
