<template>
  <div class="flex flex-col h-full bg-night-surface">
		<InstructionHeader
			:title="$t('Deur sluiten')"
			instruction="Druk door tot je 'klik' hoort"
			background="blue"
		/>

		<FullVideoInstruction :video="videoUrl">
			<AppButton @click="completeRefillSession" width="full">{{ $t('Voltooien') }}</AppButton>
		</FullVideoInstruction>
  </div>
</template>

<script>
import { defineComponent, computed } from "@vue/composition-api";
import AppButton from '@/components/Interface/AppButton';
import InstructionHeader from "@/components/NewOperator/InstructionHeader";
import FullVideoInstruction from '@/components/NewOperator/FullVideoInstruction';
import useOperator from "@/composables/useOperator";
import drinksVideo from '@/assets/videos/operator/drinks/close-door.mp4'
import vendingVideo from '@/assets/videos/operator/vending/close-door.mp4'

export default defineComponent({
  components: {
		InstructionHeader,
    FullVideoInstruction,
		AppButton,
  },

	setup(props, { root }) {
		const { OperatorAPI, machineType } = useOperator();

		const completeRefillSession = async () => {
			await OperatorAPI.finishRefill();
			root.$router.push({ name: 'OperatorMachineChecks' });
		}

		const videoUrl = computed(() => {
			if (machineType.value === 'drinks') {
				return drinksVideo;
			}

			return vendingVideo;
		});

		return {
			completeRefillSession,
			machineType,
			videoUrl,
		};
	}
});
</script>
