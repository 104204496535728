<template>
  <div class="flex flex-col h-full bg-night-surface">
    <InstructionHeader
			:title="$t('Ontvangen items')"
			:instruction="$t('Wil je <strong>alle producten</strong> bijvullen?')"
			background="blue-alt" />

		<div class="px-4 pt-4 pb-12 flex-grow flex flex-col justify-between space-y-4">
			<div class="grid gap-4">
				<h3 class="text-base leading-6 font-medium text-white">
					{{ $t(`${slotsThatCanBeRefilled.length} verschillende ontvangen producten`) }}
				</h3>

				<div class="grid gap-2 overflow-auto rounded-lg pb-32 scroll-container-height">
					<ReceivedProduct
						v-for="item, index in slotsThatCanBeRefilled"
						:shelve-slot="item"
						:product="item.product"
						:key="index"
						:is-new="false"
						:is-selecting-items="isSelectingItems"
						:is-selected="slotsToRefill.includes(item.id)"
						@toggle-slot="toggleSlotInSlotsToRefill" />
				</div>
			</div>

			<div class="grid grid-cols-2 gap-2 fixed z-20 bg-night-surface pt-4" style="bottom: 16px; width: calc(100% - 32px)">
				<AppButton @click="isSelectingItems = !isSelectingItems" type="secondary" width="full" :dark-only="true">
					{{ isSelectingItems ? $t('Terug') : $t('Aanpassen') }}
				</AppButton>

				<AppButton @click="continueToOpenDoor" width="full" :disabled="slotsToRefill.length == 0">
					{{ isSelectingItems ? $t(`${slotsToRefill.length} bijvullen ->`) : $t('Alles bijvullen ->')  }}
				</AppButton>
			</div>

			<div class="fixed z-20 pointer-events-none" :style="{
				bottom: '76px',
				background: 'linear-gradient(0deg, #121212 0%, rgba(18, 18, 18, 0) 100%)',
				height: '88px',
				width: '100%',
			}"></div>
		</div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from "@vue/composition-api";
import InstructionHeader from "@/components/NewOperator/InstructionHeader";
import ReceivedProduct from "@/components/NewOperator/ReceivedItems/ReceivedProduct";
import AppButton from '@/components/Interface/AppButton';
import useOperator from '@/composables/useOperator';

export default defineComponent({
  components: {
    InstructionHeader,
    ReceivedProduct,
		AppButton,
  },

	setup(props, { root }) {
		const {
			slotsToRefill,
			flatMappedSlots,
			setCurrentRefillingShelvePosition,
		} = useOperator();

		const slotsThatCanBeRefilled = computed(() => {

			return flatMappedSlots.value
				.filter(slot => slot?.max_to_fill > 0)
				.sort((a, b) => Number(a.bms_slot_reference) < (Number(b.bms_slot_reference)))
		});

		onMounted(() => {
			slotsToRefill.value = slotsThatCanBeRefilled.value.map(slot => slot.id);
		});

		const isSelectingItems = ref(false);

		const toggleSlotInSlotsToRefill = (slotId) => {
			// Check if slot id is in slotsToRefill
			const slotIndex = slotsToRefill.value.indexOf(slotId);

			// If it is, remove it
			if (slotIndex > -1) {
				slotsToRefill.value.splice(slotIndex, 1);
				return;
			}

			// If it isn't, add it
			slotsToRefill.value.push(slotId);
		}

		const continueToOpenDoor = async () => {
			if (!isSelectingItems.value) {
				slotsToRefill.value = slotsThatCanBeRefilled.value.map(slot => slot.id);
			}

			if (slotsToRefill.value.length == 0) {
				return;
			}

			setCurrentRefillingShelvePosition();

			root.$router.push({ name: 'OperatorOpenDoor' });
		};

		return {
			slotsThatCanBeRefilled,
			isSelectingItems,
			slotsToRefill,
			toggleSlotInSlotsToRefill,
			continueToOpenDoor,
		};
	}
});
</script>

<style scoped>
.scroll-container-height {
	height: calc(100vh - 164px - 16px - 24px - 16px - 16px);

	@screen lg {
		height: calc(80vh - 164px - 16px - 24px - 16px - 16px);
	}
}
</style>
