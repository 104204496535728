<template>
  <div class="flex flex-col h-full bg-night-surface">
    <InstructionHeader
			:title="$t('Bijvullen')"
			:instruction="$t(`We gaan nu de lade <strong>per slot bijvullen.</strong> Let er op dat je de producten <strong>goed plaatst.</strong>`)"
			background="yellow" />

		<div class="flex-grow">
			<MachineShape>
				<MachineGridShelve
					:shelve="currentRefillingShelve"
					:current-slot-index="currentSlotIndex"
				/>

				<SelectedProduct
					v-if="currentRefillingSlot"
					:shelve-slot="currentRefillingSlot"
					:percentage="currentRefillingSlotsPercentage"
					@next-slot="refillNextSlot"
				/>
			</MachineShape>
		</div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, onMounted } from "@vue/composition-api";
import InstructionHeader from "@/components/NewOperator/InstructionHeader";
import MachineShape from "@/components/NewOperator/MachineShape";
import MachineGridShelve from "@/components/NewOperator/MachineGridShelve";
import SelectedProduct from "@/components/NewOperator/RefillProducts/SelectedProduct";
import AppButton from '@/components/Interface/AppButton';
import useOperator from "@/composables/useOperator";

export default defineComponent({
  components: {
    InstructionHeader,
    MachineShape,
		SelectedProduct,
		MachineGridShelve,
		AppButton,
  },

	setup(props, { root }) {
		const {
			OperatorAPI,
			currentRefillingShelve,
			seenCloseShelveInstructions,
			isFinalShelve,
			currentRefillingShelvePosition,
			slotsToRefill,
			refillData,
			machineType,
		} = useOperator();

		const currentRefillingSlot = ref(null);

		onMounted(() => {
			// Find first slot that is in slotsToRefill in the currentRefellingShelve
			currentRefillingSlot.value = currentRefillingShelve.value.slots.find(slot => {
				return slotsToRefill.value.includes(slot.id);
			});
		});

		const currentSlotIndex = computed(() => {
			if (!currentRefillingSlot.value?.id) {
				return 0;
			}

			return currentRefillingShelve.value.slots.findIndex(slot => slot.id === currentRefillingSlot.value?.id);
		});

		const refillNextShelve = async () => {
			if (machineType.value === 'drinks') {
				// Array of slot ids of all slots in the currentRefillingShelve that are in refillData and have a higher addedStock than 0
				const refilledSlots = currentRefillingShelve.value.slots.filter(slot => {
					return slotsToRefill.value.includes(slot.id);
				}).map(slot => slot.id);

				OperatorAPI.stepmotorForward(refilledSlots);

				root.$router.push({ name: 'OperatorPositionSlots' });
				return;
			}

			if (!seenCloseShelveInstructions.value) {
				root.$router.push({ name: 'OperatorCloseShelveInstruction' });
				return;
			}

			if (isFinalShelve.value) {
				root.$router.push({ name: 'OperatorConfirmation' });
				return;
			}

			currentRefillingShelvePosition.value++;
			root.$router.push({ name: 'OperatorRefillShelve' });
		}

		const submitDataFromCurrentSlot = async (slotId) => {
			const refillDataForSlot = refillData.value.find((slot) => slot.slotId === slotId);

			if (!refillDataForSlot || !slotId) {
				return;
			}

			await OperatorAPI.submitSlotRefill({
				slotId,
				initalStock: refillDataForSlot?.initalStock ?? 0,
				addedStock: refillDataForSlot?.addedStock ?? 0,
			});
		}

		const refillNextSlot = async (slotId) => {
			await submitDataFromCurrentSlot(slotId);

			const nextSlot = currentRefillingShelve.value.slots[currentSlotIndex.value + 1];

			if (!nextSlot) {
				refillNextShelve();
				return;
			}

			currentRefillingSlot.value = nextSlot;

			if (!slotsToRefill.value.includes(nextSlot.id)) {
				refillNextSlot();
			}
		}

		const currentRefillingSlotsPercentage = computed(() => {
			return ((currentSlotIndex.value) + 0.5) * (100 / currentRefillingShelve.value.slots.length);
		});

		return {
			currentRefillingShelve,
			currentRefillingSlot,
			refillNextSlot,
			currentRefillingSlotsPercentage,
			currentSlotIndex,
		}
	}
});
</script>
