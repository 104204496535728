<template>
	<div class="operator-instruction-header relative">
		<img :src="require(`@/assets/images/operator/header/${background}.jpg`)"
			class="absolute w-full h-full inset-0 object-cover object-right-center" />

		<div class="relative z-10 p-4">
			<div class="px-4 pt-4 pb-3 flex items-center justify-between elements-shadow">
				<button class="flex-shrink-0" type="button">
					<!-- <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M18.995 11.0041H7.40501L11.705 5.71409C11.8933 5.52579 11.9991 5.27039 11.9991 5.00409C11.9991 4.73779 11.8933 4.4824 11.705 4.29409C11.5167 4.10579 11.2613 4 10.995 4C10.7287 4 10.4733 4.10579 10.285 4.29409L4.28501 11.2941C4.19397 11.3892 4.12261 11.5013 4.07501 11.6241C3.975 11.8676 3.975 12.1406 4.07501 12.3841C4.12261 12.5068 4.19397 12.619 4.28501 12.7141L10.285 19.7141C10.378 19.8078 10.4886 19.8822 10.6104 19.933C10.7323 19.9838 10.863 20.0099 10.995 20.0099C11.127 20.0099 11.2577 19.9838 11.3796 19.933C11.5015 19.8822 11.6121 19.8078 11.705 19.7141C11.7987 19.6211 11.8731 19.5105 11.9239 19.3887C11.9747 19.2668 12.0008 19.1361 12.0008 19.0041C12.0008 18.8721 11.9747 18.7414 11.9239 18.6195C11.8731 18.4977 11.7987 18.3871 11.705 18.2941L7.40501 13.0041H18.995C19.2602 13.0041 19.5146 12.8987 19.7021 12.7112C19.8897 12.5237 19.995 12.2693 19.995 12.0041C19.995 11.7389 19.8897 11.4845 19.7021 11.297C19.5146 11.1094 19.2602 11.0041 18.995 11.0041Z" fill="white"/>
					</svg> -->
				</button>

				<span class="flex-grow text-center text-base leading-6 font-medium text-white">{{ title }}</span>

				<button @click="exitRefillingSessionDialogOpened = !exitRefillingSessionDialogOpened" class="flex-shrink-0" type="button">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M18.6554 16.9916L13.6639 12L18.6554 7.00845C18.876 6.78781 19 6.48855 19 6.17652C19 5.86449 18.876 5.56523 18.6554 5.34459C18.4348 5.12395 18.1355 5 17.8235 5C17.5114 5 17.2122 5.12395 16.9916 5.34459L12 10.3361L7.00845 5.34459C6.78781 5.12395 6.48855 5 6.17652 5C5.86449 5 5.56524 5.12395 5.3446 5.34459C5.12395 5.56523 5 5.86449 5 6.17652C5 6.48855 5.12395 6.78781 5.3446 7.00845L10.3361 12L5.3446 16.9916C5.12395 17.2122 5 17.5114 5 17.8235C5 18.1355 5.12395 18.4348 5.3446 18.6554C5.56524 18.876 5.86449 19 6.17652 19C6.48855 19 6.78781 18.876 7.00845 18.6554L12 13.6639L16.9916 18.6554C17.2122 18.876 17.5114 19 17.8235 19C18.1355 19 18.4348 18.876 18.6554 18.6554C18.876 18.4348 19 18.1355 19 17.8235C19 17.5114 18.876 17.2122 18.6554 16.9916Z" fill="white"/>
					</svg>
				</button>
			</div>

			<div class="pb-5 pt-3">
				<div class="tooltip" v-html="instruction"></div>
			</div>
		</div>

		<Portal to="dialogs">
			<ExitRefillingSessionDialog
				v-if="exitRefillingSessionDialogOpened"
				@close="exitRefillingSessionDialogOpened = !exitRefillingSessionDialogOpened" />
		</Portal>
	</div>
</template>

<script>
import ExitRefillingSessionDialog from '@/components/Dialogs/ExitRefillingSession.vue';
import useOperator from "@/composables/useOperator";
import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
	props: {
		title: String,
		instruction: String,
		background: String,
	},

	components: {
		ExitRefillingSessionDialog,
	},

	setup(props, { root }) {
		const { resetOperatorData } = useOperator();

		const exitRefillingSessionDialogOpened = ref(false);

		// const exitRefilling = () => {
		// 	resetOperatorData();
		// 	root.$router.push({ name: 'HomeScreen' });
		// };

		return {
			exitRefillingSessionDialogOpened,
			// exitRefilling,
		};
	},
});
</script>

<style lang="scss" scoped>
.operator-instruction-header {
	min-height: 164px;

	&::before {
		content: '';
		@apply w-full absolute top-0 inset-x-0 z-10;
		height: 90px;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.16) 0%, rgba(0, 0, 0, 0) 100%);
	}
}

.elements-shadow {
	filter: drop-shadow(1px 1px 0.5px rgba(0, 0, 0, 0.08));
}

.tooltip {
	@apply bg-white font-normal leading-5 p-3 text-black text-sm rounded-lg relative;
	box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
	width: 60%;

	&::after {
		content: '';
		background-image: url("data:image/svg+xml,%3Csvg width='8' height='15' viewBox='0 0 8 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.04297 7.04297L6.15715e-07 0L0 14.0859L7.04297 7.04297Z' fill='white'/%3E%3C/svg%3E%0A");		@apply absolute bg-contain bg-no-repeat;
		top: 50%;
		width: 7.04px;
		height: 14.09px;
		transform: translateY(-50%);
		right: -7.04px;
	}
}
</style>
