import Vue from 'vue';
import axios from 'axios';
import { reactive, toRefs, computed } from '@vue/composition-api';

const axiosInstance = axios.create({
	baseURL: process.env.VUE_APP_OPERATOR_API_URL,
});

axiosInstance.interceptors.response.use((response) => response, (error) => {
  if (error?.response?.data?.message) {
		Vue.$toast.open({
				message: error.response.data.message,
				type: 'error',
				duration: 5000,
		});
	} else {
		Vue.$toast.open({
			message: i18n.t('default-error', {
				error: 'Server error',
			}),
			type: 'error',
			duration: 5000,
	});
	}

	return Promise.reject(error);
});

const state = reactive({
	loading: false,
	error: null,
	machineOperatorData: null,
	refillData: [],
	refillReference: null,
	seenOpenShelveInstructions: false,
	currentRefillingShelvePosition: 1,
	seenCloseShelveInstructions: false,
	slotsToRefill: [],
});

export default function useOperator() {
	const resetOperatorData = () => {
		state.machineOperatorData = null;
		state.seenOpenShelveInstructions = false;
		state.currentRefillingShelvePosition = 1;
		state.seenCloseShelveInstructions = false;
	}

	const machineNumber = computed(() => {
		return state.machineOperatorData?.machine_number;
	});

	const machineType = computed(() => {
		return state.machineOperatorData?.type;
	});

	const OperatorAPI = {
		async fetchMachineOperatorData(machineNumber) {
			const response = await axiosInstance.get(`/machine/${machineNumber}`);
			state.machineOperatorData = response.data.data;

			// Set refill data array
			state.refillData = state.machineOperatorData.shelves.flatMap(shelve => {
				return shelve.slots.map(slot => ({
						slotId: slot.id,
						initalStock: slot.stock,
						addedStock: slot.max_to_fill,
						initalStockNext: slot.next_product_stock,
						addedStockNext: slot.next_max_to_fill,
						lessStockAddedReason: '',
				}));
			});
		},

		async initRefillAndUnlockDoor() {
			const response = await axiosInstance.post(`/machine/${machineNumber.value}/init-refill`);
			state.refillReference = response.data.data.reference;
		},

		async initDrinksHoming() {
			const response = await axiosInstance.post(`/machine/${machineNumber.value}/init-homing`, {
				refill_identifier: state.refillReference,
				slots: state.slotsToRefill.map(slot => ({ slot_id: slot })),
			});
		},

		async unlockMachineDoor() {
			const response = await axiosInstance.get(`/machine/${machineNumber.value}/unlock`);
		},

		async finishRefill() {
			const response = await axiosInstance.put(`/refill/${state.refillReference}`, {
				machine: machineNumber.value,
			});
		},

		async submitSlotRefill({ slotId, initalStock, addedStock }) {
			const response = await axiosInstance.put(`/machine/${machineNumber.value}/${slotId}/refill`, {
				refill_identifier: state.refillReference,
				initial_stock: initalStock,
				added_stock: addedStock,
				// initial_stock_next: refillData.initalStockNext,
				// added_stock_next: refillData.addedStockNext,
			});
		},

		async stepmotorForward(refilledSlots) {
			const response = await axiosInstance.post(`/machine/${machineNumber.value}/stepmotor`, {
				refill_identifier: state.refillReference,
				slots: refilledSlots.map(slot => ({ slot_id: slot })),
			});
		},

		async machineStatus() {
			const response = await axiosInstance.get(`/machine/${machineNumber.value}/status`);
			return response;
		},
	};

	const shelves = computed(() => {
		if (state.machineOperatorData) {
			return state.machineOperatorData.shelves;
		}
		return [];
	});

	const currentRefillingShelve = computed(() => {
		return shelves.value.find(shelve => {
			return shelve.position === (state.currentRefillingShelvePosition - 1);
		});
	});

	const isFinalShelve = computed(() => {
		if (state.currentRefillingShelvePosition === shelves.value.length) {
			return true;
		}

		const nextShelve = shelves.value.find(shelve => {
			return shelve.position === state.currentRefillingShelvePosition;
		});

		// If next shelve has slots that need to be refilled, then it is not the final shelve
		if (nextShelve) {
			return !nextShelve.slots.find(slot => {
				return state.slotsToRefill.includes(slot.id);
			});
		}

		return false;
	});

	const flatMappedSlots = computed(() => {
		return state.machineOperatorData?.shelves.flatMap(shelf => shelf?.slots) ?? [];
	});

	const setCurrentRefillingShelvePosition = () => {
		const shelveWithSlotToRefill = state.machineOperatorData?.shelves.find(shelve => {
			return shelve.slots.find(slot => {
				return state.slotsToRefill.includes(slot.id);
			});
		});

		if (shelveWithSlotToRefill) {
			state.currentRefillingShelvePosition = shelveWithSlotToRefill.position + 1;
		}
	}

	const amountOfShelvesToRefill = computed(() => {
		return state.machineOperatorData?.shelves.filter(shelve => {
			return shelve.slots.find(slot => {
				return state.slotsToRefill.includes(slot.id);
			});
		}).length ?? 0;
	});

	const amountOfSlotsToRefill = computed(() => {
		return state.slotsToRefill.length;
	});

	return {
		...toRefs(state),
		machineNumber,
		machineType,
		resetOperatorData,
		OperatorAPI,
		shelves,
		currentRefillingShelve,
		isFinalShelve,
		flatMappedSlots,
		setCurrentRefillingShelvePosition,
		amountOfShelvesToRefill,
		amountOfSlotsToRefill,
	}
}
