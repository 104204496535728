<template>
  <div class="flex flex-col h-full bg-night-surface">
		<InstructionHeader
			:title="$t('Lade sluiten')"
			instruction="Druk door tot je 'klik' hoort"
			background="yellow"
		/>

		<FullVideoInstruction :video="videoUrl">
			<AppButton @click="next" width="full">{{ $t('Verder ->') }}</AppButton>
		</FullVideoInstruction>
  </div>
</template>

<script>
import { defineComponent, computed } from "@vue/composition-api";
import AppButton from '@/components/Interface/AppButton';
import InstructionHeader from "@/components/NewOperator/InstructionHeader";
import FullVideoInstruction from '@/components/NewOperator/FullVideoInstruction';
import useOperator from "@/composables/useOperator";
import drinksVideo from '@/assets/videos/operator/drinks/close-shelve.mp4'
import vendingVideo from '@/assets/videos/operator/vending/close-shelve.mp4'

export default defineComponent({
  components: {
		InstructionHeader,
    FullVideoInstruction,
		AppButton,
  },

	setup(props, { root }) {
		const {
			isFinalShelve,
			currentRefillingShelvePosition,
			seenCloseShelveInstructions,
			machineType,
		} = useOperator();

		const next = () => {
			seenCloseShelveInstructions.value = true;

			if (isFinalShelve.value) {
				root.$router.push({ name: 'OperatorConfirmation' });
				return;
			}

			currentRefillingShelvePosition.value++;
			root.$router.push({ name: 'OperatorRefillShelve' });
		}

		const videoUrl = computed(() => {
			if (machineType.value === 'drinks') {
				return drinksVideo;
			}

			return vendingVideo;
		});

		return {
			next,
			machineType,
			videoUrl,
		}
	},
});
</script>
