<template>
  <div class="flex flex-col h-full bg-night-surface">
    <InstructionHeader
			:title="$t('Slots positioneren')"
			:instruction="$t(headerDescription)"
			background="yellow" />

		<div class="flex-grow">
			<MachineShape>
				<MachineGridShelve :shelve="currentRefillingShelve" />

				<div v-if="allowNextStep" class="flex space-x-2 py-3 px-4 rounded-lg bg-secondary-success">
					<svg class="flex-shrink-0" width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect width="32" height="24" rx="12" fill="#27AE60"/>
						<path d="M22.399 7.57903C22.3218 7.50108 22.2299 7.4392 22.1287 7.39697C22.0275 7.35475 21.9189 7.33301 21.8093 7.33301C21.6996 7.33301 21.5911 7.35475 21.4899 7.39697C21.3887 7.4392 21.2968 7.50108 21.2196 7.57903L14.2016 14.6155L10.7714 11.1721C10.6913 11.0946 10.5966 11.0336 10.4929 10.9927C10.3892 10.9517 10.2785 10.9317 10.1671 10.9336C10.0556 10.9355 9.94566 10.9594 9.84344 11.0039C9.74122 11.0484 9.64875 11.1126 9.57131 11.1929C9.49387 11.2732 9.43298 11.3679 9.39211 11.4718C9.35125 11.5756 9.33121 11.6865 9.33313 11.7981C9.33506 11.9097 9.35892 12.0199 9.40335 12.1222C9.44778 12.2246 9.51191 12.3172 9.59207 12.3947L13.6119 16.4203C13.6891 16.4983 13.7809 16.5602 13.8822 16.6024C13.9834 16.6446 14.0919 16.6663 14.2016 16.6663C14.3112 16.6663 14.4198 16.6446 14.521 16.6024C14.6222 16.5602 14.714 16.4983 14.7912 16.4203L22.399 8.80167C22.4833 8.72379 22.5505 8.62926 22.5966 8.52405C22.6426 8.41884 22.6663 8.30522 22.6663 8.19035C22.6663 8.07549 22.6426 7.96187 22.5966 7.85666C22.5505 7.75144 22.4833 7.65692 22.399 7.57903Z" fill="white"/>
					</svg>

					<span class="text-white text-sm leading-5">{{ $t('Je kan door naar de volgende stap') }}</span>
				</div>

				<template #bottom>
						<AppButton @click="refillNextShelve" width="full" :disabled="!allowNextStep">
							{{ $t('Verder') }}
						</AppButton>
				</template>
			</MachineShape>
		</div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, onMounted } from "@vue/composition-api";
import InstructionHeader from "@/components/NewOperator/InstructionHeader";
import MachineShape from "@/components/NewOperator/MachineShape";
import MachineGridShelve from "@/components/NewOperator/MachineGridShelve";
import SelectedProduct from "@/components/NewOperator/RefillProducts/SelectedProduct";
import AppButton from '@/components/Interface/AppButton';
import useOperator from "@/composables/useOperator";

export default defineComponent({
  components: {
    InstructionHeader,
    MachineShape,
		SelectedProduct,
		MachineGridShelve,
		AppButton,
  },

	setup(props, { root }) {
		const {
			currentRefillingShelve,
			seenCloseShelveInstructions,
			isFinalShelve,
			currentRefillingShelvePosition,
		} = useOperator();

		const allowNextStep = ref(false);

		const headerDescription = computed(() => {
			if (!allowNextStep) {
				return root.$t('<strong>Slots</strong> worden <strong>terug in positie gezet</strong>, zodat de lade kan sluiten.')
			}

			return root.$t('De <strong>slots</strong> staan weer in de <strong>juiste posities</strong>!')
		});

		onMounted(async () => {
			setTimeout(() => {
				allowNextStep.value = true;
			}, 2000);
		});

		const refillNextShelve = () => {
			if (!seenCloseShelveInstructions.value) {
				root.$router.push({ name: 'OperatorCloseShelveInstruction' });
				return;
			}

			if (isFinalShelve.value) {
				root.$router.push({ name: 'OperatorConfirmation' });
				return;
			}

			currentRefillingShelvePosition.value++;
			root.$router.push({ name: 'OperatorRefillShelve' });
		}

		return {
			currentRefillingShelve,
			refillNextShelve,
			allowNextStep,
			headerDescription,
		}
	}
});
</script>
