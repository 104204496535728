<template>
	<div class="bg-night-02dp p-2 rounded-lg flex gap-1" :class="{ selected }">
		<div class="w-full flex justify-between space-x-1" :class="{ disabled }">
			<MachineShelveSlot
				v-for="slot, index in shelve.slots"
				:key="slot.id"
				:shelve-slot="slot"
				:image-only="imageOnly"
				:completed="currentSlotIndex > index || isConfirmationScreen"
				:shelve-selected="selected ? selected : true"
				:is-correcting-stock="isCorrectingStock"
			/>
		</div>
	</div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import MachineShelveSlot from "@/components/NewOperator/MachineShelveSlot";

export default defineComponent({
	components: {
		MachineShelveSlot
	},

	props: {
		shelve: {
			type: Object,
			required: true,
		},
		imageOnly: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		selected: {
			type: Boolean,
			default: false,
		},
		currentSlotIndex: {
			type: Number,
			default: 0,
		},
		isConfirmationScreen: {
			type: Boolean,
			default: false,
		},
		isCorrectingStock: {
			type: Boolean,
			default: false,
		},
	},
});
</script>

<style lang="scss" scoped>
@keyframes machine-grid-disabled {
	from {
		opacity: 1;
	}

	to {
		opacity: 0.1;
	}
}

@keyframes machine-grid-selected {
	from {
		border: 3px solid rgba(237, 74, 104, 0);
	}

	to {
		border: 3px solid #ED4A68;
	}
}

.disabled {
	animation: machine-grid-disabled 0.8s 1 linear;
	animation-fill-mode: forwards;
	animation-delay: 0.5s;
}

.selected {
	animation: machine-grid-selected 0.8s 1 linear;
	animation-fill-mode: forwards;
	animation-delay: 0.5s;
	border: 3px solid rgba(237, 74, 104, 0);
}
</style>
