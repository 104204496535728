<template>
	<div class="flex-grow relative -mt-4">
		<video
			autoplay="autoplay"
			muted
			playsinline
			loop
			class="rounded-t-2xl w-full h-full object-cover">
			<source :src="video" type="video/mp4" />
		</video>

		<div class="fixed-buttons grid grid-flow-col gap-3 fixed z-20">
			<slot />
		</div>
	</div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
	props: {
		video: String,
	},
});
</script>

<style lang="scss" scoped>
.fixed-buttons {
	bottom: 16px;
	width: calc(100% - 32px);
	left: 16px;
}
</style>
