<template>
	<div class="bg-night-03dp rounded-lg p-4 text-white text-sm leading-5 flex space-x-4 items-center"
		@click="$emit('toggle-slot', shelveSlot.id)"
		:class="{ 'pointer-events-none': !isSelectingItems }">

		<button
			v-if="isSelectingItems"
			type="button"
			class="w-6 h-6 border-2 border-gray-400 rounded flex justify-center items-center flex-shrink-0">

			<svg v-if="isSelected" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M14.6948 3.85085L6.97723 11.5886L3.29535 7.89255L3.29538 7.89252L3.29205 7.8893C3.17827 7.77923 3.04393 7.69266 2.8967 7.63456C2.74947 7.57645 2.59224 7.54795 2.43399 7.55069C2.27574 7.55343 2.11959 7.58736 1.97446 7.65053C1.82933 7.7137 1.69806 7.80487 1.58815 7.91881C1.47825 8.03275 1.39184 8.16724 1.33386 8.31458C1.27588 8.46193 1.24744 8.61925 1.25018 8.77758C1.25292 8.93591 1.28676 9.09215 1.3498 9.2374C1.41254 9.38195 1.50297 9.5128 1.61598 9.62255L6.1361 14.1491C6.13624 14.1493 6.13638 14.1494 6.13652 14.1496C6.24652 14.2605 6.37736 14.3486 6.52152 14.4088C6.66588 14.469 6.82072 14.5 6.97712 14.5C7.13351 14.5 7.28836 14.469 7.43271 14.4088C7.57688 14.3486 7.70772 14.2605 7.81772 14.1496C7.81786 14.1494 7.818 14.1493 7.81814 14.1491L16.3728 5.58224C16.4911 5.47189 16.5857 5.33843 16.6506 5.1901C16.7161 5.04014 16.75 4.87821 16.75 4.71451C16.75 4.55082 16.7161 4.38889 16.6506 4.23893C16.5857 4.09066 16.4912 3.95725 16.3729 3.84692C16.2636 3.73762 16.134 3.65075 15.9914 3.59124C15.847 3.53101 15.6922 3.5 15.5358 3.5C15.3794 3.5 15.2246 3.53101 15.0802 3.59124C14.936 3.6514 14.8052 3.73952 14.6951 3.8505C14.695 3.85062 14.6949 3.85074 14.6948 3.85085Z" fill="#ED4A68" stroke="#ED4A68" stroke-width="0.5"/>
			</svg>
		</button>

		<img :src="product.image" :alt="product.name" class="flex-shrink-0 h-16 w-16 object-contain" />

		<div class="flex flex-col space-y-1 flex-grow">
			<div v-if="isNew">
				<div class="inline bg-primary-brand rounded-full px-2 py-0.5">
					<span class="text-white text-xs leading-6 font-medium">{{ $t('Nieuw!') }}</span>
				</div>
			</div>

			<span class="font-medium">{{ product.name }}</span>
		</div>

		<button class="flex-shrink-0 bg-night-08dp rounded-lg px-4 py-2">
			<span>{{ shelveSlot.max_to_fill }}x</span>
		</button>
	</div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
	props: {
		product: Object,
		shelveSlot: Object,
		isNew: Boolean,
		isSelectingItems: Boolean,
		isSelected: Boolean,
	},
});
</script>
