<template>
	<div class="rounded-full grid grid-cols-3 gap-1 items-center bg-gray-50">
		<AppIcon
			@click="amount > 0 ? $emit('decrease') : null"
			icon-name="minus"
			:width="20"
			:height="20"
			class="py-3 pl-4 cursor-pointer flex justify-center text-black"
			:class="{ 'opacity-25 pointer-events-none': amount === 0 }"
		/>

		<span class="text-center text-sm leading-5 font-medium text-black">{{ amount }}</span>

		<AppIcon
			@click="amount < max ? $emit('increase') : null"
			icon-name="plus"
			:width="20"
			:height="20"
			class="py-3 pr-4 cursor-pointer flex justify-center text-black"
			:class="{ 'opacity-25 pointer-events-none': amount >= max }"
		/>
	</div>
</template>

<script>
import AppIcon from '@/components/Interface/AppIcon.vue';

export default {
	props: {
		amount: {
			type: Number,
			required: true,
		},
		max: {
			type: Number,
			default: 99999,
		},
	},

	components: {
		AppIcon,
	},
};
</script>

