<template>
  <div class="flex flex-col h-full bg-night-surface">
		<InstructionHeader
			:title="$t('Deur openen')"
			:instruction="$t('Je kan nu de deur openen')"
			background="blue-alt"
		/>

		<FullVideoInstruction :video="videoUrl">
			<AppButton @click="unlockDoor" type="secondary" width="full" :disabled="!enableUnlockDoorButton" :dark-only="true">
				{{ $t('Ontgrendelen') }}
			</AppButton>

			<RouterLink :to="{ name: 'OperatorRefillShelve' }">
				<AppButton width="full">{{ $t('Verder ->') }}</AppButton>
			</RouterLink>
		</FullVideoInstruction>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from "@vue/composition-api";
import AppButton from '@/components/Interface/AppButton';
import InstructionHeader from "@/components/NewOperator/InstructionHeader";
import FullVideoInstruction from '@/components/NewOperator/FullVideoInstruction';
import useOperator from '@/composables/useOperator';
import drinksVideo from '@/assets/videos/operator/drinks/open-door.mp4'
import vendingVideo from '@/assets/videos/operator/vending/open-door.mp4'

export default defineComponent({
  components: {
		InstructionHeader,
    FullVideoInstruction,
		AppButton,
  },

	setup() {
		const { OperatorAPI, machineType } = useOperator();

		const enableUnlockDoorButton = ref(false);

		onMounted(async () => {
			await OperatorAPI.initRefillAndUnlockDoor();

			if (machineType.value === 'drinks') {
				await OperatorAPI.initDrinksHoming();
			}

			setTimeout(() => {
				enableUnlockDoorButton.value = true;
			}, 10000);
		});

		const unlockDoor = async () => {
			await OperatorAPI.unlockMachineDoor();
			enableUnlockDoorButton.value = false;

			setTimeout(() => {
				enableUnlockDoorButton.value = true;
			}, 10000);
		};

		const videoUrl = computed(() => {
			if (machineType.value === 'drinks') {
				return drinksVideo;
			}

			return vendingVideo;
		});

		return {
			enableUnlockDoorButton,
			unlockDoor,
			machineType,
			videoUrl,
		};
	},
});
</script>

