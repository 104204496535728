<template>
  <div class="flex flex-col h-full bg-night-surface">
    <InstructionHeader
			:title="$t('Fantastisch!')"
			:instruction="$t(`Alle lades zijn bijgevuld.`)"
			background="blue" />

		<div class="flex-grow">
			<MachineShape>
				<MachineGridShelve
					v-for="shelve in shelves"
					:key="shelve.position"
					:shelve="shelve"
					:selected="false"
					:image-only="true"
					:is-confirmation-screen="true"
				/>

				<template #bottom>
					<RouterLink :to="{ name: 'OperatorCloseDoor' }">
						<AppButton width="full">Deur sluiten -></AppButton>
					</RouterLink>
				</template>
			</MachineShape>
		</div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import InstructionHeader from "@/components/NewOperator/InstructionHeader";
import MachineShape from "@/components/NewOperator/MachineShape";
import MachineGridShelve from "@/components/NewOperator/MachineGridShelve";
import AppButton from '@/components/Interface/AppButton';
import useOperator from "@/composables/useOperator";

export default defineComponent({
  components: {
    InstructionHeader,
    MachineShape,
		MachineGridShelve,
		AppButton,
  },

	setup(props, { root }) {
		const { shelves } = useOperator();

		return {
			shelves,
		}
	}
});
</script>
