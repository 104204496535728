<template>
	<!-- eslint-disable max-len -->
	<BaseDialog @close="$emit('close')" class="ExitRefillingSession">
		<div class="ExitRefillingSession_Message">
			<h2 class="font-semibold">{{ $t('Exit refilling session') }}</h2>
			<p class="mt-1 text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">{{ $t('Are you sure you want to exit?') }}</p>
		</div>

		<div class="ExitRefillingSession_Actions mt-6 grid row-gap-2">
			<AppButton @click="exitRefillingSession" type="secondary" width="full">{{ $t('order.disconnect.disconnect-button') }}</AppButton>
		</div>
	</BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Interface/BaseDialog.vue';
import AppButton from '@/components/Interface/AppButton.vue';
import AppIcon from '@/components/Interface/AppIcon.vue';
import useOperator from "@/composables/useOperator";

export default {
	name: 'ExitRefillingSession',

	components: {
		BaseDialog,
		AppButton,
		AppIcon,
	},

	setup(props, { root }) {
		const { resetOperatorData } = useOperator();

		const exitRefillingSession = () => {
			resetOperatorData();
			root.$router.push({ name: 'HomeScreen' });
		};

		return {
			exitRefillingSession,
		};
	},
};
</script>

<style lang="scss">
.ExitRefillingSession {
	&_Icon {
		bottom: 16px;
		right: 64px;
	}

	&_Actions .ui-AppButton {
		@apply px-3;
	}
}
</style>
