<template>
  <div class="flex flex-col h-full bg-night-surface">
    <InstructionHeader
			:title="$t('Geweldig!')"
			:instruction="instructionMessage"
			background="pink"
		/>

		<div class="px-4 pt-4 pb-12 flex-grow flex flex-col justify-between space-y-4 overflow-auto">
			<div class="flex flex-col gap-4">
				<div v-if="!machineChecksDone" class="flex space-x-2 items-center">
					<svg class="spinner" viewBox="0 0 100 100" width="20" height="20">
						<circle cx="50" cy="50" r="42" transform="rotate(-90,50,50)" />
					</svg>

					<p class="text-base leading-6 text-white font-medium">We controleren nu of de deur is dichtgedaan.</p>
				</div>

				<!-- Server error -->
				<div v-if="machineChecksDone && machineHasErrors" class="p-4 flex gap-2 rounded-2xl bg-night-03dp">
					<svg class="flex-shrink-0" width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect x="0.5" width="21.9999" height="21.9999" rx="11" fill="#EB5757"/>
						<path d="M15.5676 14.0508L12.5172 11.0004L15.5676 7.95004C15.7024 7.8152 15.7782 7.63232 15.7782 7.44164C15.7782 7.25095 15.7024 7.06808 15.5676 6.93324C15.4328 6.79841 15.2499 6.72266 15.0592 6.72266C14.8685 6.72266 14.6856 6.79841 14.5508 6.93324L11.5004 9.98363L8.45004 6.93324C8.3152 6.79841 8.13233 6.72266 7.94164 6.72266C7.75095 6.72266 7.56808 6.79841 7.43324 6.93324C7.29841 7.06808 7.22266 7.25095 7.22266 7.44164C7.22266 7.63232 7.29841 7.8152 7.43324 7.95004L10.4836 11.0004L7.43324 14.0508C7.29841 14.1856 7.22266 14.3685 7.22266 14.5592C7.22266 14.7499 7.29841 14.9328 7.43324 15.0676C7.56808 15.2024 7.75095 15.2782 7.94164 15.2782C8.13232 15.2782 8.3152 15.2024 8.45004 15.0676L11.5004 12.0172L14.5508 15.0676C14.6856 15.2024 14.8685 15.2782 15.0592 15.2782C15.2499 15.2782 15.4328 15.2024 15.5676 15.0676C15.7024 14.9328 15.7782 14.7499 15.7782 14.5592C15.7782 14.3685 15.7024 14.1856 15.5676 14.0508Z" fill="white"/>
					</svg>

					<div>
						<p>Er is een probleem opgetreden:</p>

						<ul class=" list-disc list-inside">
							<li>Zijn de lades goed aangedrukt?</li>
							<li>Zit de deur goed dicht?</li>
						</ul>
					</div>
				</div>

				<!-- Success message -->
				<div v-if="machineChecksDone && !machineHasErrors" class="p-4 flex gap-2 rounded-2xl bg-night-03dp">
					<svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect x="0.5" width="21.9999" height="21.9999" rx="11" fill="#27AE60"/>
						<path d="M17.3658 6.94818C17.295 6.87672 17.2108 6.82 17.118 6.78129C17.0252 6.74258 16.9257 6.72266 16.8252 6.72266C16.7247 6.72266 16.6252 6.74258 16.5324 6.78129C16.4397 6.82 16.3555 6.87672 16.2847 6.94818L9.8515 13.3982L6.70723 10.2418C6.63374 10.1707 6.547 10.1148 6.45194 10.0773C6.35689 10.0398 6.25538 10.0214 6.15323 10.0232C6.05107 10.025 5.95027 10.0469 5.85656 10.0876C5.76286 10.1284 5.6781 10.1873 5.60711 10.2609C5.53613 10.3345 5.48031 10.4213 5.44285 10.5165C5.40539 10.6117 5.38702 10.7134 5.38879 10.8157C5.39056 10.918 5.41243 11.0189 5.45315 11.1128C5.49388 11.2066 5.55266 11.2915 5.62615 11.3626L9.31096 15.0527C9.38173 15.1241 9.46593 15.1808 9.55871 15.2196C9.65148 15.2583 9.75099 15.2782 9.8515 15.2782C9.952 15.2782 10.0515 15.2583 10.1443 15.2196C10.2371 15.1808 10.3213 15.1241 10.392 15.0527L17.3658 8.06893C17.443 7.99754 17.5047 7.91089 17.5469 7.81444C17.5891 7.718 17.6109 7.61385 17.6109 7.50855C17.6109 7.40326 17.5891 7.29911 17.5469 7.20267C17.5047 7.10622 17.443 7.01957 17.3658 6.94818Z" fill="white"/>
					</svg>

					<span class="text-base font-medium leading-6 text-white">{{ $t('Machine is operationeel') }}</span>
				</div>

				<!-- Share on WhatsApp -->
				<div v-if="machineChecksDone && !machineHasErrors" @click="shareOnWhatsApp" class="p-4 flex gap-2 rounded-2xl bg-night-03dp cursor-pointer">
					<svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M17.8547 4.55156C15.8906 2.58281 13.275 1.5 10.4953 1.5C4.75781 1.5 0.0890625 6.16875 0.0890625 11.9062C0.0890625 13.7391 0.567188 15.5297 1.47656 17.1094L0 22.5L5.51719 21.0516C7.03594 21.8813 8.74687 22.3172 10.4906 22.3172H10.4953C16.2281 22.3172 21 17.6484 21 11.9109C21 9.13125 19.8188 6.52031 17.8547 4.55156ZM10.4953 20.5641C8.93906 20.5641 7.41562 20.1469 6.08906 19.3594L5.775 19.1719L2.50313 20.0297L3.375 16.8375L3.16875 16.5094C2.30156 15.1313 1.84688 13.5422 1.84688 11.9062C1.84688 7.13906 5.72812 3.25781 10.5 3.25781C12.8109 3.25781 14.9812 4.15781 16.6125 5.79375C18.2437 7.42969 19.2469 9.6 19.2422 11.9109C19.2422 16.6828 15.2625 20.5641 10.4953 20.5641ZM15.2391 14.0859C14.9813 13.9547 13.7016 13.3266 13.4625 13.2422C13.2234 13.1531 13.05 13.1109 12.8766 13.3734C12.7031 13.6359 12.2063 14.2172 12.0516 14.3953C11.9016 14.5688 11.7469 14.5922 11.4891 14.4609C9.96094 13.6969 8.95781 13.0969 7.95 11.3672C7.68281 10.9078 8.21719 10.9406 8.71406 9.94687C8.79844 9.77344 8.75625 9.62344 8.69062 9.49219C8.625 9.36094 8.10469 8.08125 7.88906 7.56094C7.67813 7.05469 7.4625 7.125 7.30313 7.11563C7.15313 7.10625 6.97969 7.10625 6.80625 7.10625C6.63281 7.10625 6.35156 7.17188 6.1125 7.42969C5.87344 7.69219 5.20312 8.32031 5.20312 9.6C5.20312 10.8797 6.13594 12.1172 6.2625 12.2906C6.39375 12.4641 8.09531 15.0891 10.7062 16.2188C12.3562 16.9312 13.0031 16.9922 13.8281 16.8703C14.3297 16.7953 15.3656 16.2422 15.5812 15.6328C15.7969 15.0234 15.7969 14.5031 15.7313 14.3953C15.6703 14.2781 15.4969 14.2125 15.2391 14.0859Z" fill="white"/>
					</svg>

					<div class="grid">
						<span class="text-base leading-6 text-white font-medium">Delen via WhatsApp -></span>
						<p class="text-night-on-surface-medium-emphasis">Breng je collega’s op de hoogte van de nieuwe voorraad snacks in de Junea.</p>
					</div>
				</div>
			</div>

			<div class="flex space-x-2">
				<AppButton v-if="!machineChecksDone || machineHasErrors" type="secondary" width="auto" class="flex-1" :dark-only="true">{{ $t('Ontgrendelen') }}</AppButton>
				<AppButton v-if="machineChecksDone" @click="completeRefill" width="auto" class="flex-1">{{ $t('Voltooien') }}</AppButton>
			</div>
		</div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from "@vue/composition-api";
import InstructionHeader from "@/components/NewOperator/InstructionHeader";
import AppButton from '@/components/Interface/AppButton';
import useOperator from "@/composables/useOperator";

export default defineComponent({
  components: {
    InstructionHeader,
		AppButton,
  },

	setup(props, { root }) {
		const { resetOperatorData, machineNumber, OperatorAPI } = useOperator();

		const shareOnWhatsApp = () => {
			const message = `Er is een nieuwe voorraad snacks in de Junea. Ga naar de Junea app om te zien welke snacks er zijn: https://junea.app/?machine=${machineNumber.value}`;
			const url = `https://wa.me/?text=${encodeURIComponent(message)}`;

			window.open(url, '_blank');
		}

		const completeRefill = () => {
			resetOperatorData();
			root.$router.push({ name: 'HomeScreen' });
		}

		const machineChecksDone = ref(false);
		const machineHasErrors = ref(false);

		const fetchMachineStatus = async () => {
			const response = await OperatorAPI.machineStatus();

			if (response.status === 200 && response.data.status === "operational") {
				machineChecksDone.value = true;
			} else {
				setTimeout(() => {
					fetchMachineStatus();
				}, 3000);
			}
		}

		const instructionMessage = computed(() => {
			if (machineChecksDone.value) {
				return root.$t(`De Junea is weer klaar voor gebruik! <strong>Deel</strong> met collega's en bestel!`);
			}

			return root.$t('De Junea wordt nu <strong>gecontroleerd</strong>. Dit kan even duren.');
		});

		onMounted(() => {
			fetchMachineStatus();
		});

		return {
			shareOnWhatsApp,
			completeRefill,
			machineChecksDone,
			machineHasErrors,
			instructionMessage,
		}
	}
});
</script>

<style scoped>
@-webkit-keyframes spin {
	to {
		stroke-dashoffset: -264;
	}
}

@keyframes spin {
	to {
		stroke-dashoffset: -264;
	}
}

.spinner circle {
	fill: none;
	stroke: #ED4A68;
	stroke-width: 16;
	stroke-linecap: round;
	stroke-dasharray: 0, 0, 70, 194;
	stroke-dashoffset: 0;
	animation: spin 1.4s infinite linear;
	-webkit-animation: spin 1.4s infinite linear;
}
</style>
