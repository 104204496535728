<template>
  <div class="flex flex-col h-full bg-night-surface">
    <InstructionHeader
			:title="$t('We gaan bijvullen')"
			:instruction="$t(`We gaan <strong>${amountOfShelvesToRefill} lades bijvullen</strong> met <strong>${amountOfSlotsToRefill} producten</strong>. Laten we ${currentRefillingShelvePosition === 1 ? 'beginnen' : 'verder'} met <strong>lade ${currentRefillingShelvePosition}</strong>.`)"
			background="blue-alt" />

		<div class="flex-grow">
			<MachineShape>
				<MachineGridShelve
					v-for="shelve in shelves"
					:key="shelve.position"
					:shelve="shelve"
					:image-only="true"
					:disabled="(shelve.position + 1) !== currentRefillingShelvePosition"
					:selected="(shelve.position + 1) === currentRefillingShelvePosition"
				/>

				<template #bottom>
					<AppButton @click="refillShelve" width="full">Bijvullen lade {{ currentRefillingShelvePosition }} -></AppButton>
				</template>
			</MachineShape>
		</div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import InstructionHeader from "@/components/NewOperator/InstructionHeader";
import MachineShape from "@/components/NewOperator/MachineShape";
import MachineGridShelve from "@/components/NewOperator/MachineGridShelve";
import AppButton from '@/components/Interface/AppButton';
import useOperator from "@/composables/useOperator";

export default defineComponent({
  components: {
    InstructionHeader,
    MachineShape,
		MachineGridShelve,
		AppButton,
  },

	setup(props, { root }) {
		const {
			shelves,
			seenOpenShelveInstructions,
			currentRefillingShelvePosition,
			amountOfShelvesToRefill,
			amountOfSlotsToRefill,
		} = useOperator();

		const refillShelve = () => {
			if (!seenOpenShelveInstructions.value) {
				root.$router.push({ name: 'OperatorOpenShelveInstruction' });
				return;
			}

			root.$router.push({ name: 'OperatorCheckInventory' });
		}

		return {
			shelves,
			refillShelve,
			currentRefillingShelvePosition,
			amountOfShelvesToRefill,
			amountOfSlotsToRefill,
		}
	}
});
</script>
