<template>
	<div class="flex-1 flex flex-col justify-center items-center relative"
		:class="{ 'opacity-25': shelveSelected && !allowRefillSlot && !isCorrectingStock }">

		<img v-if="shelveSlot.product && shelveSlot.product.image"
			:src="shelveSlot.product.image"
			class="object-contain rounded-lg h-16" />

		<span v-if="!imageOnly" class="text-base text-white leading-6 font-medium">
			{{ isCorrectingStock ? refillDataForSlot.initalStock : refillDataForSlot.initalStock + refillDataForSlot.addedStock }} x
		</span>

		<svg v-if="completed && allowRefillSlot" class="check-complete" width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="0.547852" y="0.435547" width="32" height="24" rx="12" fill="#27AE60"/>
			<path d="M22.9468 8.01458C22.8696 7.93662 22.7777 7.87475 22.6765 7.83252C22.5753 7.79029 22.4668 7.76855 22.3571 7.76855C22.2475 7.76855 22.1389 7.79029 22.0377 7.83252C21.9365 7.87475 21.8447 7.93662 21.7675 8.01458L14.7494 15.051L11.3193 11.6077C11.2391 11.5301 11.1445 11.4691 11.0408 11.4282C10.9371 11.3873 10.8264 11.3672 10.7149 11.3691C10.6035 11.3711 10.4935 11.395 10.3913 11.4395C10.2891 11.4839 10.1966 11.5482 10.1192 11.6284C10.0417 11.7087 9.98083 11.8035 9.93997 11.9073C9.8991 12.0112 9.87906 12.1221 9.88099 12.2337C9.88291 12.3453 9.90677 12.4554 9.9512 12.5578C9.99563 12.6601 10.0598 12.7527 10.1399 12.8303L14.1597 16.8559C14.2369 16.9338 14.3288 16.9957 14.43 17.0379C14.5312 17.0801 14.6398 17.1019 14.7494 17.1019C14.859 17.1019 14.9676 17.0801 15.0688 17.0379C15.17 16.9957 15.2619 16.9338 15.3391 16.8559L22.9468 9.23722C23.0311 9.15934 23.0984 9.06481 23.1444 8.9596C23.1904 8.85438 23.2142 8.74076 23.2142 8.6259C23.2142 8.51104 23.1904 8.39742 23.1444 8.2922C23.0984 8.18699 23.0311 8.09246 22.9468 8.01458Z" fill="white"/>
		</svg>
	</div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
import useOperator from "@/composables/useOperator";

export default defineComponent({
	props: {
		shelveSlot: {
			type: Object,
			required: true,
		},
		imageOnly: {
			type: Boolean,
			default: false,
		},
		completed: {
			type: Boolean,
			default: false,
		},
		shelveSelected: {
			type: Boolean,
			default: false,
		},
		isCorrectingStock: {
			type: Boolean,
			default: false,
		},
	},

	setup(props) {
		const { slotsToRefill, refillData } = useOperator();

		const allowRefillSlot = computed(() => {
			return slotsToRefill.value.includes(props.shelveSlot.id);
		});

		const refillDataForSlot = computed(() => {
			return refillData.value.find((slot) => slot.slotId === props.shelveSlot.id);
		});

		return {
			allowRefillSlot,
			refillDataForSlot,
		};
	},
});
</script>

<style lang="scss" scoped>
@keyframes pop-in {
	from {
		transform: scale(0) translate(calc(-50% - 33px), calc(-50% - 25px));
	}

	to {
		transform: scale(1) translate(-50%, calc(-50% - 8px));
	}
}

.check-complete {
	position: absolute;
	top: 50%;
	left: 50%;
	// transform: translate(-50%, -50%);
	z-index: 1;
	animation: pop-in 0.3s 1 ease-in-out;
	animation-fill-mode: forwards;
}
</style>
