<template>
	<div class="bg-night-03dp rounded-lg p-4 text-white text-sm leading-5">
		<slot />
	</div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
	props: {
		text: String,
	}
});
</script>
