<template>
  <div class="flex flex-col h-full bg-night-surface">
		<InstructionHeader
			:title="$t('Lade openen')"
			instruction="Je kan nu de lade openen"
			background="blue-alt"
		/>

		<FullVideoInstruction :video="videoUrl">
			<RouterLink :to="{ name: 'OperatorCheckInventory' }">
				<AppButton width="full">{{ $t('Verder ->') }}</AppButton>
			</RouterLink>
		</FullVideoInstruction>
  </div>
</template>

<script>
import { defineComponent, computed } from "@vue/composition-api";
import AppButton from '@/components/Interface/AppButton';
import InstructionHeader from "@/components/NewOperator/InstructionHeader";
import FullVideoInstruction from '@/components/NewOperator/FullVideoInstruction';
import useOperator from "@/composables/useOperator";
import drinksVideo from '@/assets/videos/operator/drinks/open-shelve.mp4';
import vendingVideo from '@/assets/videos/operator/vending/open-shelve.mp4';

export default defineComponent({
  components: {
		InstructionHeader,
    FullVideoInstruction,
		AppButton,
  },

	setup(props, { root }) {
		const {
			seenOpenShelveInstructions,
			machineType,
		} = useOperator();

		const videoUrl = computed(() => {
			if (machineType.value === 'drinks') {
				return drinksVideo;
			}

			return vendingVideo;
		});

		seenOpenShelveInstructions.value = true;

		return {
			videoUrl
		}
	}
});
</script>
